import { apiCall } from "./ApiService";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import * as sha512 from "js-sha512";

const environnement = process.env.REACT_APP_ENV || "";
const test = process.env.REACT_APP_USER_TEST || "";
export default class AuthenticationService {
  static user: any = {} as any;
  static isAuthenticated: boolean = false;

  static async login(code: string): Promise<boolean> {
    const apiCodeAuthObject = {
      terminaison: "codeAuth",
      code: code,
      clientId: environnement,
    };
    await apiCall(apiCodeAuthObject).then((result) => {
      try {
        this.user = JSON.parse(result.data.response);
        this.isAuthenticated = true;
      } catch (e) {
        this.isAuthenticated = false;
      }
    });
    if (this.isAuthenticated === true) {
      const apiObject = {
        terminaison: "postLog",
        item: {
          id: { S: uuidv4() },
          timestamp: { N: moment().unix().toString() },
          action: { S: "Login" },
          date: { S: moment().format("DD/MM/YYYY, HH:mm") },
          type: { S: "Authentication" },
          details: { S: "New login" },
          utilisateur: {
            S:
              AuthenticationService.user.user.firstname +
              " " +
              AuthenticationService.user.user.name,
          },
        },
      };
      await apiCall(apiObject);
    }

    return new Promise((resolve) => {
      resolve(this.isAuthenticated);
    });
  }

  static async backupLogin(email: string, password: string): Promise<string> {
    const hashPassword = sha512.sha512(password);

    const apiCodeAuthObject = {
      terminaison: "backupLogin",
      password: hashPassword,
      email: email,
    };
    await apiCall(apiCodeAuthObject).then((result) => {
      try {
        this.user = JSON.parse(result.data.response);
        this.isAuthenticated = true;
      } catch (e) {
        this.isAuthenticated = false;
      }
    });
    if (this.isAuthenticated === true) {
      const apiObject = {
        terminaison: "postLog",
        item: {
          id: { S: uuidv4() },
          timestamp: { N: moment().unix().toString() },
          action: { S: "Login" },
          date: { S: moment().format("DD-MM-YYYY, HH:mm") },
          type: { S: "Authentication" },
          details: { S: "New login" },
          utilisateur: {
            S:
              AuthenticationService.user.user.firstname +
              " " +
              AuthenticationService.user.user.name,
          },
        },
      };
      await apiCall(apiObject);
      return new Promise((resolve) => {
        resolve("OK");
      });
    } else {
      return new Promise((resolve) => {
        resolve("Email and/or password is not correct");
      });
    }
  }

  static async testLogin(): Promise<boolean> {
    try {
      this.user = JSON.parse(test);
      this.isAuthenticated = true;
    } catch (e) {
      this.isAuthenticated = false;
    }
    return new Promise((resolve) => {
      resolve(this.isAuthenticated);
    });
  }
}
