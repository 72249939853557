import axios from "axios";
import AuthenticationService from "./AuthenticationService";

const env = process.env.REACT_APP_ENV || "";
const apiUrl = env === 'prod' ? process.env.REACT_APP_API_URL || "" : process.env.REACT_APP_DEV_API_URL || "";
const apiKey = env=== 'prod' ? process.env.REACT_APP_API_KEY || "" : process.env.REACT_APP_DEV_API_KEY || "";

export async function apiCall(object: any): Promise<any> {
  object.env = env;
  // En mode dev on n'envoie pas de logs
  if (
    AuthenticationService.user &&
    ((object.terminaison === "postLog" && env !== "dev") ||
      object.terminaison !== "postLog")
  )
    return axios.post(apiUrl + "/ucesorh", object, {
      headers: {
        "x-api-key": apiKey,
      },
    });
  else
    return new Promise((res, err) => {
      return res("Phase de test");
    });
}
