import React from "react";
import { Card, Button, Grid, useMediaQuery } from "@material-ui/core";
import styled from "@emotion/styled";
import logo from "../Images/logo3.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { apiCall } from "../Service/ApiService";

const environnement = process.env.REACT_APP_ENV || "";
const websiteUrl =
  process.env.REACT_APP_WEBSITE_URL ||
  "https://d1pwomvskqowim.cloudfront.net/login";

const StyledCardLitleScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCardBigScreen = styled(Card)`
  padding: 10px;
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  fond: {
    backgroundColor: "#2D3E4E",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginTop: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}));

//Composant de connexion
const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const petit = useMediaQuery(theme.breakpoints.down("md"));

  const handleAngeliqueLogin = async () => {
    try {
      const apiAuthObject = {
        terminaison: "auth",
        redirect_uri: websiteUrl,
        clienId: environnement,
      };
      const response = await apiCall(apiAuthObject);
      const url = JSON.parse(response.data.response).url;
      window.location.assign(url);
    } catch (error) {
      console.error(error);
    }
  };
  
  let button;
  if (environnement === "prod"){
    button = <Button
              variant="contained"
              color="primary"
              onClick={handleAngeliqueLogin}
              className={classes.btn}
            >
              Connexion Angelique
            </Button>
  } else {
    button = <Button
              variant="contained"
              color="primary"
              onClick={handleAngeliqueLogin}
              className={classes.btn}
            >
              Connexion Angelique (dev)
            </Button>
  }


  return (
    <div style={{ height: "100vh" }} className={classes.fond}>
      <div style={{ height: "25vh" }}></div>

      {petit && (
        <StyledCardLitleScreen elevation={4}>
          <Grid container alignContent="center" justifyContent="center">
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "15vh" }}
            ></Grid>
            <br />
            { button }
          </Grid>
        </StyledCardLitleScreen>
      )}

      {!petit && (
        <StyledCardBigScreen>
          <Grid container alignContent="center" justifyContent="center">
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              className={classes.logo}
              style={{ height: "20vh" }}
            ></Grid>
            <br />
            { button }
          </Grid>
        </StyledCardBigScreen>
      )}
    </div>
  );
};

export default Home;
