import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Select,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import Close from "@material-ui/icons/Close";
import Save from "@material-ui/icons/Save";
import Calculate from "@material-ui/icons/Replay";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useSnackbar } from "notistack";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AuthenticationService from "../Service/AuthenticationService";
import { sendLogs } from "./Uceso/Uceday";
import { apiCall } from "../Service/ApiService";
import WarningIcon from "@material-ui/icons/Warning";

const env = process.env.REACT_APP_ENV;

const useStyles = makeStyles((theme) => ({
  addUser: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  discontiniousSchedule: {
    marginBottom: theme.spacing(2),
  },
  editJ_button: {
    marginRight: theme.spacing(2),
  },
  AjoutPForm: {
    marginBottom: theme.spacing(3),
  },
  AjoutPForm_fin: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  AjoutPForm_button: {
    marginRight: theme.spacing(2),
  },
  save: {
    marginRight: theme.spacing(2),
  },
  ucesoOpenList: {
    marginBottom: theme.spacing(0.5),
  },
  spaceBetweenUceso: {
    marginBottom: theme.spacing(1),
  },
  error: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const ModalDeleteHoraire = (props: any) => {
  const classes = useStyles();

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>
          Vous êtes sur le point de supprimer {props.nom}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            La suppression sera irréversible. <br /> Etes-vous sur de vouloir
            supprimer ce fichier ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                props.deleteHoraire();
              }}
              className={classes.AjoutPForm_button}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalDiscontinuousSchedule = (props: any) => {
  const classes = useStyles();

  return (
    props.open && (
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="lg"
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#2D3E4E",
            color: "white",
          }}
        >
          Les fichiers horaires ne sont pas continus pour l'année en cours !
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ maxHeight: "80vh" }}
          >
            <Grid item xs={10}>
              <Typography
                style={{ textAlign: "center" }}
                className={classes.addUser}
              >
                Intervalles discontinus
              </Typography>
              <Grid container alignItems="center" alignContent="center">
                {props.discontinuousSchedule.discontinuousZone.map(
                  (schedule: string[], index: number) => {
                    return (
                      <Grid item xs={10} sm={6} key={index}>
                        <Typography
                          variant="body1"
                          style={{ textAlign: "center" }}
                        >
                          {schedule[0]} - {schedule[1]}
                        </Typography>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
            <Grid item xs={8} className={classes.addUser}>
              <Divider />
            </Grid>
            <Grid item xs={10}>
              <Typography
                className={classes.discontiniousSchedule}
                style={{ textAlign: "center" }}
              >
                Fichiers horaires
              </Typography>
              <Grid container alignContent="center">
                {props.discontinuousSchedule.horaire.map(
                  (schedule: { titre: string; validite: any }) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={schedule.titre}
                        className={classes.discontiniousSchedule}
                      >
                        <Typography
                          variant="body1"
                          style={{ textAlign: "center" }}
                        >
                          {schedule.titre} :
                        </Typography>
                        <List dense={true}>
                          {schedule.validite.map(
                            (validite: any[], index: number) => {
                              return (
                                <ListItem key={index}>
                                  <ListItemText style={{ textAlign: "center" }}>
                                    {validite[0]} - {validite[1]}
                                  </ListItemText>
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalOctimeDifference = (props: any) => {
  return (
    props.open && (
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="sm"
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#2D3E4E",
            color: "white",
          }}
        >
          <WarningIcon />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ maxHeight: "80vh" }}
          >
            <Grid item xs={10}>
              <Grid container alignItems="center" alignContent="center">
                {props.recycleurs.map((recycleur: string, index: number) => {
                  return (
                    <Grid item xs={10} sm={12} key={index}>
                      <Typography
                        variant="body1"
                        style={{ textAlign: "center" }}
                      >
                        {recycleur}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalIntervalCrossing = (props: any) => {
  const classes = useStyles();

  return (
    props.open && (
      <Dialog
        open={props.open}
        fullWidth
        maxWidth="sm"
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            textAlign: "center",
            backgroundColor: "#2D3E4E",
            color: "white",
          }}
        >
          Un ou plusieurs fichier(s) sont déjà valables sur cet intervalle
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ maxHeight: "80vh" }}
          >
            <Grid item xs={10}>
              <Grid container alignItems="center" alignContent="center">
                {props.intervalles.map((intervalle: string, index: number) => {
                  return (
                    <Grid item xs={10} sm={12}>
                      <Typography
                        variant="body1"
                        style={{ textAlign: "center" }}
                      >
                        {intervalle[0]}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ textAlign: "center" }}
                      >
                        {intervalle[1]}
                      </Typography>
                      {index !== props.intervalles.length - 1 && (
                        <Divider className={classes.divider} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalAddAttribution = (props: any) => {
  const classes = useStyles();

  const [selected, setSelected] = useState([] as any);
  const [selectedAjout, setSelectedAjout] = useState([] as any);
  const [horaire, setHoraire] = React.useState("J1");

  const onClickValider = () => {
    for (let index = 0; index < selected.length; index++) {
      const element = selected[index];
      Object.defineProperty(element, "horaire", {
        value: horaire,
        writable: true,
        enumerable: true,
      });
      selected[index] = element;
    }

    props.getNewPersonnel(selected, selectedAjout, horaire);
    setSelected([]);
    setSelectedAjout([]);
  };

  const handleClickAjout = (
    matricule: any,
    nom: any,
    prenom: any,
    poste: any
  ) => {
    //Permet de sélectionner un utilisateur lorsqu'on clique dessus
    const selectedIndex: any = selectedAjout.indexOf(matricule);

    let newSelected: any[] = [];
    let newSelect: any[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAjout, matricule);
      newSelect = newSelect.concat(selected, {
        matricule: matricule,
        horaireNum: undefined,
        nom: nom,
        prenom: prenom,
        absence: undefined,
        poste: poste,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAjout.slice(1));
      newSelect = newSelect.concat(selected.slice(1));
    } else if (selectedIndex === selectedAjout.length - 1) {
      newSelected = newSelected.concat(selectedAjout.slice(0, -1));
      newSelect = newSelect.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAjout.slice(0, selectedIndex),
        selectedAjout.slice(selectedIndex + 1)
      );
      newSelect = newSelect.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelect);
    setSelectedAjout(newSelected);
  };

  const isSelectedAjout = (matricule: any) =>
    selectedAjout.indexOf(matricule) !== -1;

  const handleChangeHoraire = (event: any) => {
    setHoraire(event.target.value);
  };

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Attribuer une nouvelle personne
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TableContainer component={Paper} className={props.classes.container}>
            <Table stickyHeader className={props.classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Nom</TableCell>
                  <TableCell align="center">Prénom</TableCell>
                  <TableCell align="center">Poste</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.listOfUnassignedAgents.map((personne: any) => {
                  //Lignes du tableau correspondant au personnel du jour
                  const isItemSelected = isSelectedAjout(personne.matricule);
                  const labelId = `checkbox-${personne.matricule}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClickAjout(
                          personne.matricule,
                          personne.nom,
                          personne.prenom,
                          personne.poste
                        )
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      key={personne.matricule}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {personne.nom}
                      </TableCell>
                      <TableCell align="center">{personne.prenom}</TableCell>
                      <TableCell align="center"> {personne.poste}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TextField
            margin="normal"
            required
            select
            id="choix"
            value={horaire}
            label="Horaire"
            name="choix"
            autoFocus
            type="choix"
            SelectProps={{
              native: true,
            }}
            onChange={handleChangeHoraire}
          >
            {props.uceDay.jours.map((jour: any) => {
              if (!jour.veille) {
                return (
                  jour.nom !== "CDS" && (
                    <option key={jour.nom} value={jour.nomOctime}>
                      {jour.nom}
                    </option>
                  )
                );
              } else {
                return (
                  jour.nom !== "CDS" && (
                    <option key={jour.nom} value={jour.nom}>
                      {jour.nom}
                    </option>
                  )
                );
              }
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.editJ_button}
              onClick={onClickValider}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalEditAttribution = (props: any) => {
  const classes = useStyles();

  const [sousJour, setSousJour] = useState("0");
  const [jour, setJour] = useState("M1");

  const handleChangeSousJour = (event: any) => {
    setSousJour(event.target.value);
  };

  const clickOnValider = () => {
    props.changeAttribution(jour, sousJour);
  };

  const handleChangeJour = (event: any) => {
    setJour(event.target.value);
  };

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Changer l'attribution
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TextField
            margin="normal"
            required
            select
            id="choix"
            value={jour}
            label="Jour"
            name="choix"
            autoFocus
            type="choix"
            SelectProps={{
              native: true,
            }}
            onChange={handleChangeJour}
          >
            {props.uceDay.jours.map((jour: any) => {
              if (!jour.veille) {
                return (
                  jour.nom !== "CDS" && (
                    <option key={jour.nom} value={jour.nomOctime}>
                      {jour.nom}
                    </option>
                  )
                );
              } else {
                return (
                  jour.nom !== "CDS" && (
                    <option key={jour.nom} value={jour.nom}>
                      {jour.nom}
                    </option>
                  )
                );
              }
            })}
          </TextField>
          <TextField
            margin="normal"
            required
            select
            id="choix"
            value={sousJour}
            label="SousJours"
            name="choix"
            autoFocus
            type="choix"
            SelectProps={{
              native: true,
            }}
            onChange={handleChangeSousJour}
          >
            {props.uceDay.jours.map((j: any) => {
              return j.sousJours.map((sousJours: any, index: number) => {
                if (!j.veille) {
                  return (
                    j.nomOctime === jour && (
                      <option key={j.nomOctime + " " + index + 1} value={index}>
                        {sousJours.nPExt > 0 && "Ext_" + (index + 1)}
                        {sousJours.nPExt <= 0 && index + 1}
                      </option>
                    )
                  );
                } else {
                  return (
                    j.nom === jour && (
                      <option key={j.nomOctime + " " + index + 1} value={index}>
                        {sousJours.nPExt > 0 && "Ext_" + (index + 1)}
                        {sousJours.nPExt <= 0 && index + 1}
                      </option>
                    )
                  );
                }
              });
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.editJ_button}
              onClick={clickOnValider}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalChoiceOpenHoraire = (props: any) => {
  const classes = useStyles();

  const [listHoraire, setListHoraire] = useState([]);
  const [selected, setSelected] = useState([] as any);
  const { enqueueSnackbar } = useSnackbar();

  const apiObject = {
    terminaison: "listHoraire",
  };
  const getAllHoraire = async () => {
    let hListeData = await apiCall(apiObject);
    try {
      let hListe = JSON.parse(hListeData.data.response).value;
      setListHoraire(hListe);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllHoraire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Réinitialise les horaires sélectionnés
  const resetSelected = () => {
    setSelected([]);
    return;
  };

  //Permet de sélectionner un horaire lorsqu'on clique dessus
  const handleClick = (event: any, name: any) => {
    let newSelected: any[] = [];

    if (selected.length !== 0) {
      if (selected[0] === name) {
        resetSelected();
      } else {
        resetSelected();
        newSelected.push(name);
        setSelected(newSelected);
      }
    } else {
      resetSelected();
      newSelected.push(name);
      setSelected(newSelected);
    }
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  //Charge le fichier horaire
  const loadH = async (file: any) => {
    const apiObject = {
      terminaison: "getHoraire",
      title: file,
    };
    let newHoraire = await apiCall(apiObject).then((result) => {
      return JSON.parse(result.data.response);
    });

    return newHoraire;
  };

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Changer de fichier horaire
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <TableContainer component={Paper} className={props.classes.container}>
            <Table stickyHeader className={props.classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">Nom</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listHoraire.length !== 0 &&
                  listHoraire.map((horaire: any, index: number) => {
                    //Lignes du tableau correspondant au personnel du jour
                    const isItemSelected = isSelected(horaire);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, horaire)}
                        role="checkbox"
                        selected={isItemSelected}
                        key={horaire}
                        tabIndex={-1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isItemSelected} />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {horaire}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.editJ_button}
              onClick={async () => {
                if (selected.length !== 0) {
                  let horaire = await loadH(selected[0]);
                  props.getHoraire(horaire);
                } else {
                  enqueueSnackbar("Aucun horaire séléctionné", {
                    variant: "error",
                    autoHideDuration: 3000,
                  });
                }
              }}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalDeleteUser = (props: any) => {
  const classes = useStyles();

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>
          Vous êtes sur le point de supprimer{" "}
          {props.selected.map((user: any, i: number) =>
            props.selected.length === i + 1 ? ` ${user} ` : ` ${user}, `
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            {props.selected.length > 1 && (
              <>
                La suppression sera irréversible. <br /> Etes-vous sur de
                vouloir supprimer ces utilisateurs ?
              </>
            )}

            {props.selected.length === 1 && (
              <>
                La suppression sera irréversible. <br /> Etes-vous sur de
                vouloir supprimer cet utilisateur ?
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                props.deleteUser();
              }}
              className={classes.AjoutPForm_button}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};

export const ModalChooseUcesoDate = (props: any) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>Veuillez saisir une date</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            error={false}
            helperText={false ? "Date déjà choisie" : ""}
            margin="normal"
            id="ucesodate"
            label="Date du graphique Uceso"
            value={props.selectedDate}
            onChange={props.handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.validation();
              props.closeModal();
            }}
            className={classes.AjoutPForm_button}
          >
            Valider
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              props.closeModal();
            }}
            color="secondary"
          >
            Fermer
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const ModalManageUceso = (props: any) => {
  const classes = useStyles();

  const [saltoModal, setSaltoModal] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("" as any);
  const [currentDate, setCurrentDate] = useState("" as any);
  const [currentData, setCurrentData] = useState("" as any);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeSaltoModal = () => {
    setSaltoModal(false);
  };

  async function signSalto(title: string, data: any, date: string) {
    let infoKey = enqueueSnackbar("Export du fichier en cours", {
      variant: "info",
      autoHideDuration: 8000,
    });
    const apiObject = {
      terminaison: "salto",
      title: title,
      data: data,
      date: date,
      user:
        AuthenticationService.user.user.firstname +
        " " +
        AuthenticationService.user.user.name,
    };
    await apiCall(apiObject)
      .then(() => {
        closeSnackbar(infoKey);
        enqueueSnackbar("Export réussi", {
          variant: "success",
          autoHideDuration: 6000,
        });
        sendLogs(
          "Sign",
          "Upload du fichier salto du " +
            moment(date, "MM-DD-YYYY").format("DD/MM/YYYY") +
            " pour signature"
        );
      })
      .catch(() => {
        closeSnackbar(infoKey);
        enqueueSnackbar("Une erreur s'est produite", {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  }

  return (
    <Dialog open={props.open} onClose={props.closeModal} maxWidth="xs">
      <DialogTitle>Liste des graphes</DialogTitle>
      <DialogContent>
        {props.uceDays.length !== 0 &&
          props.uceDays.map((uceDay: any, i: number) => (
            <Grid
              container
              style={{ border: "1px solid", height: 180 }}
              alignItems="center"
              justifyContent="center"
              className={classes.spaceBetweenUceso}
              key={i}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    {moment
                      .tz(`${uceDay.date}T0000`, "Europe/Paris")
                      .format("dddd DD MMMM YYYY")}
                  </Grid>
                  <Grid item xs={12}>
                    Horaire - {props.horaire[i].titre + "     "}
                  </Grid>
                  <Grid item xs={12}>
                    {props.user.role.droits.uceso.editHoraire &&
                      props.editH[i] && (
                        <Select
                          defaultValue={props.horaire[i].titre + ".json"}
                          onChange={(e) => {
                            props.modalEditH(e, i);
                          }}
                        >
                          {props.chargeList}
                        </Select>
                      )}
                    {props.user.role.droits.uceso.editHoraire &&
                      !props.editH[i] && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            props.modalEditH(null, i);
                            props.getHListe();
                          }}
                        >
                          Changer{" "}
                          {/*Si changement, Select, handleChange effectue le changement et recalcule tout*/}
                        </Button>
                      )}
                  </Grid>
                  <Grid item>
                    {!moment
                      .tz(`${uceDay.date}T0000`, "Europe/Paris")
                      .isBetween(
                        moment(props.horaire[i].validite[0], "DD/MM/YYYY"),
                        moment(props.horaire[i].validite[1], "DD/MM/YYYY"),
                        undefined,
                        "[]"
                      )
                      ? "Horaire exceptionnel"
                      : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  {props.user.role.droits.uceso.sauvegarde && (
                    <>
                      <Tooltip title="Enregistrer le graphe">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          name={uceDay.date}
                          style={{
                            maxWidth: "35px",
                            maxHeight: "35px",
                            minWidth: "35px",
                            minHeight: "35px",
                          }}
                          onClick={() => {
                            props.saveUceDay(i);
                          }}
                          className={classes.save}
                        >
                          <Save
                            style={{
                              maxWidth: "25px",
                              maxHeight: "25px",
                              minWidth: "25px",
                              minHeight: "25px",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  {props.user.role.droits.uceso.salto && (
                    <>
                      <Tooltip title="Exporter le fichier salto">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          disabled={
                            uceDay.salto === "CollissionHaveHappened" ||
                            env === "dev"
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            setCurrentData(uceDay.salto);
                            setCurrentDate(
                              moment
                                .tz(`${uceDay.date}T0000`, "Europe/Paris")
                                .format("DD-MM-YYYY")
                            );
                            setCurrentTitle(
                              "UCESO_LFEE_" +
                                moment
                                  .tz(`${uceDay.date}T0000`, "Europe/Paris")
                                  .format("YYYYMMDD")
                            );
                            setSaltoModal(true);
                          }}
                          style={{
                            maxWidth: "35px",
                            maxHeight: "35px",
                            minWidth: "35px",
                            minHeight: "35px",
                          }}
                          type="button"
                          className={classes.save}
                        >
                          <ImportExportIcon
                            style={{
                              maxWidth: "25px",
                              maxHeight: "25px",
                              minWidth: "25px",
                              minHeight: "25px",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Fermer le graphe">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      name={uceDay.date}
                      onClick={(e) => {
                        props.modalCloseUceso(e, i);
                      }}
                      className={classes.save}
                      style={{
                        maxWidth: "35px",
                        maxHeight: "35px",
                        minWidth: "35px",
                        minHeight: "35px",
                      }}
                    >
                      <Close
                        style={{
                          maxWidth: "25px",
                          maxHeight: "25px",
                          minWidth: "25px",
                          minHeight: "25px",
                        }}
                      />
                    </Button>
                  </Tooltip>

                  {props.user.role.droits.uceso.reset && (
                    <Tooltip title="Recalculer les uceso depuis les données Octime et horaires les plus récentes">
                      <Button
                        size="small"
                        variant="contained"
                        name={uceDay.date}
                        style={{
                          maxWidth: "35px",
                          maxHeight: "35px",
                          minWidth: "35px",
                          minHeight: "35px",
                        }}
                        onClick={async () => {
                          props.addUceDay(
                            moment(uceDay.date, "YYYYMMDD"),
                            false
                          );
                        }}
                      >
                        <Calculate
                          style={{
                            maxWidth: "25px",
                            maxHeight: "25px",
                            minWidth: "25px",
                            minHeight: "25px",
                          }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              {props.dateCalc[i] && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  Calculé le {uceDay.dateCalc}
                </Grid>
              )}
            </Grid>
          ))}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              props.closeModal();
            }}
            color="secondary"
          >
            Fermer
          </Button>
        </Grid>
      </DialogActions>
      <ModalUploadSalto
        open={saltoModal}
        closeModal={closeSaltoModal}
        date={currentDate}
        signSalto={signSalto}
        title={currentTitle}
        data={currentData}
      />
    </Dialog>
  );
};

export const ModalAddHoraire = (props: any) => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Ajouter un horaire
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Formik
            initialValues={{ title: "", template: 0 }}
            onSubmit={(values) => {
              props.add(values);
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string()
                .required("Le titre est manquant")
                .notOneOf(props.titles, "Le titre est déjà pris"),
            })}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Titre"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      helperText={
                        errors.title && touched.title ? errors.title : ""
                      }
                      error={errors.title && touched.title ? true : false}
                      className={classes.AjoutPForm}
                    />
                  </Grid>
                  <Grid item sm={1} />
                  <Grid item xs={6} sm={4} className={classes.AjoutPForm}>
                    <FormControl>
                      <InputLabel>Modèle</InputLabel>
                      <Select
                        name="template"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.template}
                      >
                        <MenuItem value={0}> Aucun </MenuItem>
                        <MenuItem value={1}> Standard </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} md={5} className={classes.addUser}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="submit"
                    >
                      Valider
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={5}
                    className={classes.addUser}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => props.closeModal()}
                      color="secondary"
                    >
                      Fermer
                    </Button>
                  </Grid>
                  {values.template === 1 ? (
                    <Grid item style={{ textAlign: "center" }}>
                      Standard : 6 Jours + NVeille
                    </Grid>
                  ) : null}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export const ModalChargeHoraire = (props: any) => {
  const classes = useStyles();

  const [cible, setCible] = useState(props.cibleCharge);

  return (
    <Dialog open={props.open} onClose={props.closeModal} maxWidth="md">
      <DialogTitle style={{ textAlign: "center" }}>
        Charger un horaire
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {props.chargeList && props.chargeList.length !== 0 ? (
              <Select value={cible} onChange={(e) => setCible(e.target.value)}>
                {props.chargeList}
              </Select>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.charge(cible);
            }}
            className={classes.AjoutPForm_button}
          >
            Valider
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              props.closeModal();
            }}
            color="secondary"
          >
            Fermer
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const ModalChangeDate = (props: any) => {
  const classes = useStyles();

  const [date, setDate] = useState(props.date);
  const handleDateChange = (date: any) => {
    if (date && date.isValid()) {
      setDate(date);
    }
  };
  return (
    <Dialog open={props.open} onClose={props.closeModal} maxWidth="md">
      <DialogTitle style={{ textAlign: "center" }}>
        Charger un horaire
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format="DD/MM/yyyy"
                error={false}
                helperText={false ? "Date déjà choisie" : ""}
                margin="normal"
                id="ucesodate"
                label="Date du graphique Uceso"
                value={date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.changeDate(date);
            }}
            className={classes.AjoutPForm_button}
          >
            Valider
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              props.closeModal();
            }}
            color="secondary"
          >
            Fermer
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export const ModalError = (props: any) => {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle className={classes.error}>
        Une erreur est survenue &#x2639;
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center", height: 80 }}
        >
          {props.textError === "Invalid xml file" && (
            <p>
              Votre fichier xml ne semble pas correct. <br /> Veuillez utiliser
              un fichier conforme.
            </p>
          )}
          {props.textError === "staff data not accessible" && (
            <p>
              Impossible de récupérer les données du personnel pour le moment.{" "}
              <br /> Merci de rééssayer ultérieurement.
            </p>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export const ModalUploadSalto = (props: any) => {
  const classes = useStyles();

  return (
    props.open && (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Vous êtes sur le point d'exporter le fichier salto du <br />{" "}
          {props.date}
        </DialogTitle>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                props.signSalto(props.title, props.data, props.date);
              }}
              className={classes.AjoutPForm_button}
            >
              Valider
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.closeModal();
              }}
              color="secondary"
            >
              Fermer
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  );
};
